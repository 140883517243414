/*
 * Copyright © 2025 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { queryOptions } from '@tanstack/react-query'

import { api, handleResponse } from '../core'

export const profileQueries = {
  detail: {
    options: () =>
      queryOptions({
        queryFn: ({ queryKey: [_scope, _entity], signal }) =>
          handleResponse(api.profile.getProfile({ signal })),
        queryKey: [...profileQueries.keyPrefix(), 'detail'] as const,
      }),
  },
  keyPrefix: () => ['profile'] as const,
}
