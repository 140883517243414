/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { type ProfileResponse } from './backend/bo/generated/Api'

/**
 * Page permissions for the entire project. There is no strict structure here, so add what you need.
 * Keep in mind though that alphabetical sorting is enforced, so you can use this to keep related
 * things together. E.g., `mods` and `modWrappers` will be listed together, whereas
 * `mods` and `wrappersForMods` will not.
 *
 * Full list of permissions can be found here:
 * https://docs.google.com/document/d/1LoMZXIwkTE7v57wGrM64ppA-rofBsM7N1RLMgpevWPc/edit#heading=h.1g84jwak3c4o
 *
 * Probably we could refactor this into:
 * `ACCESS.<feature>.<operation>`
 * like in the document. Have fun doing that if you think it's worth the effort!
 */
export const ACCESS = {
  alerts: ['ADMIN', 'CONTENT_MANAGER'],
  challenges: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  challengesDetails: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  challengesPendingVideos: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  challengesPromotedChallenge: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  challengesSeasons: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  cms: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER', 'DYNAMIC_CONFIG_MANAGER'],
  cmsFeeds: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  cmsPageConfig: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  disputes: ['ADMIN', 'CONTENT_MANAGER'],
  dynamicConfig: ['ADMIN', 'DYNAMIC_CONFIG_MANAGER'],
  dynamicConfigClients: ['ADMIN'],
  extension: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  extensions: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  extensionsAll: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  games: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  gamesEngines: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  gamesItems: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  gamesPublishers: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  gamesRelays: ['ADMIN'],
  gamesReported: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  gamesTransfers: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  gamesWrappers: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  mods: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER', 'GM_OPS'],
  modsAll: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  modsGmsRunners: ['ADMIN', 'VIEWER', 'GM_OPS'],
  modsOdk: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  modsReports: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  modsRunnerMigration: ['ADMIN'],
  modsRunnerMigrations: ['ADMIN'],
  modsTransfers: ['ADMIN', 'CONTENT_MANAGER'],
  modsWrappers: ['ADMIN'],
  offerPartners: ['ADMIN', 'OFFER_MANAGER'],
  offers: ['ADMIN', 'OFFER_MANAGER'],
  quests: ['ADMIN', 'CONTENT_MANAGER'],
  settings: ['ADMIN'],
  stickers: ['ADMIN', 'CONTENT_MANAGER'],
  studios: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  studiosSeasons: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  tagDetails: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  tags: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  users: ['ADMIN', 'CONTENT_MANAGER', 'VIEWER'],
  usersAdmin: ['ADMIN'],
  waitList: ['ADMIN', 'CONTENT_MANAGER'],
  waitLists: ['ADMIN', 'VIEWER'],
} satisfies Record<string, Readonly<ProfileResponse['roles']>>

export const isAdmin = (profile: ProfileResponse | undefined | null) =>
  profile?.roles?.includes('ADMIN') ?? false

export const isContentManager = (profile: ProfileResponse | undefined | null) =>
  profile?.roles?.includes('CONTENT_MANAGER') ?? false

export const isViewer = (profile: ProfileResponse | undefined | null) =>
  profile?.roles?.includes('VIEWER') ?? false

export const isGmOps = (profile: ProfileResponse | undefined | null) =>
  profile?.roles?.includes('GM_OPS') ?? false

export const isDynamicConfigManager = (profile: ProfileResponse | undefined | null) =>
  profile?.roles?.includes('DYNAMIC_CONFIG_MANAGER') ?? false

export const isOfferManager = (profile: ProfileResponse | undefined | null) =>
  profile?.roles?.includes('OFFER_MANAGER') ?? false
