/*
 * Copyright © 2021 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { captureException } from '@sentry/nextjs'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import styled from 'styled-components'

import { profileQueries } from '~/backend/bo/queries/profile'
import DeprecatedGmxApi from '~/backend/gmx-api/GmxApi'
import { Button } from '~/components/Button'
import { OPERA_DEV_TOOLS } from '~/config'

import OperaGx from './OperaGx.png'

interface OperaDevToolsProps {
  initialIsOpen?: boolean
}
export const OperaDevTools = ({ initialIsOpen }: OperaDevToolsProps) => {
  const [open, setOpen] = useState<boolean>(initialIsOpen ?? false)
  const profile = useQuery(profileQueries.detail.options())

  if (!OPERA_DEV_TOOLS || !profile.data) {
    return null
  }
  return (
    <Wrapper>
      <Tools $show={open}>
        <Button
          variant="primary"
          onClick={async () => {
            try {
              // TODO(duanei): This was quickest and dirtiest. Don't judge me. We have a release to focus on.
              await DeprecatedGmxApi.post('/bo/administration/users/elevate', null, {
                params: {
                  email: profile.data.email,
                },
              })
              toast.success("You're an admin!")
            } catch (error: unknown) {
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              toast.error(`You did not become admin :(!: ${error}`)
            }
          }}
        >
          Become admin!
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            captureException(new Error('This is a test error'))
            toast.success('Reported an error to sentry!')
          }}
        >
          Test sentry!
        </Button>
      </Tools>

      <Icon
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        src={OperaGx.src}
        onClick={() => setOpen((open) => !open)}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 0.5rem;
`

const Tools = styled.div<{ $show: boolean }>`
  display: flex;
  position: absolute;
  right: 0;
  bottom: 100%;
  flex-direction: column;
  padding: 0.5rem;
  gap: 8px;

  transition: opacity 0.25s ease-in-out;

  opacity: ${({ $show }) => ($show ? 1 : 0)};

  pointer-events: ${({ $show }) => ($show ? 'auto' : 'none')};
`

const Icon = styled.img`
  width: 40px;
  height: 40px;

  cursor: pointer;
`
