/*
 * Copyright © 2021 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { useQuery } from '@tanstack/react-query'

import { profileQueries } from '~/backend/bo/queries/profile'
import { Banner } from '~/components/banner/Banner'

export const NoPermissions = () => {
  const profile = useQuery(profileQueries.detail.options())

  if (!profile.data) {
    return null
  }

  const show = !!profile.data.roles && !(profile.data.roles.length > 0) && !!profile.data.active
  return <Banner $show={show}>Please contact an admin to get access.</Banner>
}
