/*
 * Copyright © 2025 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { queryOptions } from '@tanstack/react-query'

import { GMX_API } from '~/config'

export const beVersionQueries = {
  keyPrefix: () => ['be-version'] as const,
  version: {
    options: () =>
      queryOptions({
        // Non standard fetch, as it is not actually a BE API.
        queryFn: async () => {
          try {
            const response = await fetch(`${GMX_API}/version`)
            return response.text()
          } catch (error: unknown) {
            throw error
          }
        },
        queryKey: [...beVersionQueries.keyPrefix(), 'version'] as const,
      }),
  },
}
